<template>
  <div class="playlist"
       v-loading="loading">
    <div class="playlist-top-card">
      <div class="playlist-img-wrap">
        <img :src="playlistInfo.coverImgUrl">
      </div>
      <div class="playlist-info">
        <div class="playlist-name">
          <div class="tag1">歌单</div>
          <div>{{playlistInfo.name}}</div>
        </div>
        <div class="playlist-user-info">
          <div class="playlist-user-avatar">
            <img :src="playlistUserAvatar">
          </div>
          <div class="playlist-user-name">{{playlistUserNickname}}</div>
          <div class="playlist-create-time">
            <span>{{playlistInfo.updateTime | LocaleDateString}}</span> 创建
            <span>共{{trackIds.length}}首</span>
          </div>
        </div>
        <div class="playAllBtn iconfont icon-play"
             @click="playAll"> 播放全部</div>
        <div class="playlist-tags">
          <span>标签：</span>
          <span v-for="(item,index) in playlistInfo.tags"
                :key="index"
                class="tags">{{item}}</span>
        </div>
        <div class="playlist-desc">
          <span>简介：</span>
          <span :title="playlistInfo.description">{{playlistInfo.description}}</span>
        </div>
      </div>
    </div>

    <div class="playlist-tabs-wrap">
      <el-tabs v-model="activeName">
        <el-tab-pane label="歌曲列表"
                     name="first">
          <div class="songs-table">
            <el-table :data="tableData"
                      stripe
                      @row-dblclick="play"
                      v-el-table-infinite-scroll="loadMore"
                      infinite-scroll-delay=500
                      infinite-scroll-disabled="noMore">

              <el-table-column type="index"
                               width="50"
                               v-if="indexshow"></el-table-column>

              <el-table-column :width="imgwidth">
                <template slot-scope="scope">
                  <div class="img-wrap">
                    <img v-lazy="scope.row.al.picUrl">
                    <p class="iconfont icon-play"
                       @click="play(scope.row)"></p>
                  </div>
                </template>

              </el-table-column>

              <el-table-column prop="name"
                               label="音乐标题"
                               :width="songwidth"></el-table-column>

              <el-table-column prop="artistInfo"
                               label="歌手"
                               :width="singerwidth">
                <template slot-scope="scope">
                  <div v-for="(singer,i) in scope.row.ar"
                       :key="i"
                       style="cursor:pointer;color:#2980b9;display:inline-block"
                       @click="singerDetailJump(singer.id)">{{singer.name}}<span style="color:#606266;margin:0 5px"
                          v-show="scope.row.ar.length != 1 && i!=scope.row.ar.length-1">/</span></div>
                </template>
              </el-table-column>

              <el-table-column prop="al.name"
                               label="专辑"
                               width="">
                <template slot-scope="scope">
                  <span style="cursor:pointer;color:#2980b9;"
                        @click="toAlbum(scope.row.al.id)">{{scope.row.al.name}}</span>
                  <span class="plus"
                        title="添加至歌单"
                        @click="addToQueue(scope.row,$event)"
                        style="top:20px">+</span>
                </template>
              </el-table-column>

              <el-table-column prop="dt"
                               label="时长"
                               :width="timewidth"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="`评论(${playlistInfo.commentCount})`"
                     name="second">

          <div class="comment-wrap"
               v-if="hotComments.length!=0">
            <h4 class="comment-title">最热评论({{hotComments.length}})</h4>
            <ul>
              <li v-for="(item,index) in hotComments"
                  :key="index">
                <img v-lazy="item.user.avatarUrl"
                     class="comment-avatar">
                <div class="comment-info">
                  <div class="comment">
                    <span class="comment-user">{{item.user.nickname}}:</span>
                    <span class="comment-content">{{item.content}}</span>
                  </div>
                  <div class="re-comment"
                       v-if="item.beReplied.length!=0">
                    <span class="comment-user">@{{item.beReplied[0].user.nickname}}:</span>
                    <span class="comment-content">{{item.beReplied[0].content}}</span>
                  </div>
                  <div class="comment-bottom">
                    <p class="comment-time">{{item.time | LocaleDateString}}</p>
                    <span class="comment-time iconfont icon-dianzan">{{item.likedCount}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="comment-wrap">
            <h4 class="comment-title">最新评论({{playlistInfo.commentCount-hotComments.length}})</h4>
            <ul>
              <li v-for="(item,index) in comments"
                  :key="index">
                <img v-lazy="item.user.avatarUrl"
                     alt=""
                     class="comment-avatar">
                <div class="comment-info">
                  <div class="comment">
                    <span class="comment-user">{{item.user.nickname}}:</span>
                    <span class="comment-content">{{item.content}}</span>
                  </div>
                  <div class="re-comment"
                       v-if="item.beReplied.length!=0">
                    <span class="comment-user">@{{item.beReplied[0].user.nickname}}:</span>
                    <span class="comment-content">{{item.beReplied[0].content}}</span>
                  </div>
                  <div class="comment-bottom">
                    <p class="comment-time">{{item.time | LocaleString}}</p>
                    <span class="comment-time iconfont icon-dianzan">{{item.likedCount}}</span>
                  </div>

                </div>
              </li>
            </ul>
            <div class="page-wrap">
              <el-pagination @current-change="handleCurrentChange"
                             background
                             :layout="pagi"
                             :total="total"
                             :page-size="pageSize"
                             :current-page="page">
              </el-pagination>
            </div>
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="add-ball iconfont icon-yinfu"
         v-show="showAddBall"
         ref="addBall"></div>
  </div>
</template>

<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll'
import { playlistDetailAPI, songInfoAPI, commentsAPI } from '@/api/api'

export default {
  data () {
    return {
      activeName: 'first',
      playlistInfo: {},
      playlistUserAvatar: '',
      playlistUserNickname: '',
      trackIds: [],
      tableData: [],
      comments: [],
      hotComments: [],
      total: 0,
      pageSize: 10,
      page: 1,
      playlistId: '',
      loading: true,
      loadBegin: 0,
      allData: [],
      showAddBall: false,
      indexshow: true,
      imgwidth: 100,
      songwidth: '',
      singerwidth: '',
      timewidth: 100,
      pagi: 'prev, pager, next'
    }
  },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  created () {
    const playlistId = this.$route.query.id
    this.playlistId = playlistId

    this.getTableData()

    setTimeout(() => {
      this.loadAll()
    }, 1000)
  },
  computed: {
    noMore () {
      return this.tableData.length >= this.trackIds.length
    },
    nowIndex () {
      return this.$store.state.nowIndex
    },
    musicQueue () {
      return this.$store.state.musicQueue
    },
    queuePos () {
      return this.$store.state.queuePos
    }
  },
  methods: {
    singerDetailJump (id) {
      this.$router.push(`/singerDetail?id=${id}`)
    },
    toAlbum (id) {
      this.$router.push(`/album?id=${id}`)
    },
    handleCurrentChange (page) {
      this.page = page
      this.getComments()
    },
    loadMore () {
      this.loading = true
      this.loadBegin += 20
      this.getTableData()
    },
    loadAll () {
      const ids = []
      for (const item of this.trackIds) {
        ids.push(item.id)
      }
      const idsStr1 = ids.join(',')
      this.getTracks(idsStr1, true)
    },
    addToQueue (row, e) {
      const obj = {
        id: row.id,
        imgUrl: row.al.picUrl,
        duration: row.dt,
        artistInfo: row.ar,
        songName: row.name
      }

      const ids = []
      for (const item of this.musicQueue) {
        ids.push(item.id)
      }
      if (ids.indexOf(obj.id) === -1) {
        this.beginAnimation(e.x, e.y)
        setTimeout(() => {
          this.$store.commit('changeQueueStyle', 'add')
          this.$store.commit('changeMusicQueue', obj)
        }, 500)

        setTimeout(() => {
          this.$store.commit('changeQueueStyle', 'normal')
        }, 1000)
      } else {
        this.$message.warning({
          message: '已存在播放列表哦',
          showClose: true,
          center: true
        })
      }
    },
    beginAnimation (x, y) {
      this.showAddBall = true
      const endX = this.queuePos.left
      const endY = this.queuePos.top

      const el = document.querySelector('.add-ball')
      el.style.left = x + 'px'
      el.style.top = y + 'px'

      setTimeout(() => {
        el.style.left = endX + 'px'
        el.style.top = endY + 'px'
      }, 0)

      setTimeout(() => {
        this.showAddBall = false
      }, 500)
    },
    async getTableData () {
      const { data: res } = await playlistDetailAPI({ id: this.playlistId })
      this.playlistInfo = res.playlist
      this.playlistUserAvatar = res.playlist.creator.avatarUrl
      this.playlistUserNickname = res.playlist.creator.nickname
      this.trackIds = res.playlist.trackIds

      let idsArray = []
      for (const item of this.trackIds) {
        idsArray.push(item.id)
      }
      if (this.loadBegin + 20 >= idsArray.length) {
        idsArray = idsArray.slice(this.loadBegin)
      } else {
        idsArray = idsArray.slice(this.loadBegin, this.loadBegin + 20)
      } // 歌单歌曲的数量
      const idsStr = idsArray.join(',')

      // 这一段bug
      Promise.all([this.getTracks(idsStr, false), this.getComments(true)]).then(
        () => {}
      )
      this.loading = false
    },
    async getTracks (trackId, all = false) {
      const { data: res } = await songInfoAPI({ ids: trackId })
      const pushList = []
      for (const item of res.songs) {
        const duration = item.dt
        const min = parseInt(duration / 60000)
          .toString()
          .padStart(2, '0')
        const second = parseInt((duration - min * 60000) / 1000)
          .toString()
          .padStart(2, '0')
        item.dt = `${min}:${second}`
        pushList.push(item)
      }

      if (all) {
        this.allData = this.allData.concat(pushList)
      } else {
        this.tableData = this.tableData.concat(pushList)
      }
    },
    async play (row) {
      const musicInfo = {
        imgUrl: row.al.picUrl,
        artistInfo: row.ar,
        songName: row.name,
        id: row.id,
        duration: row.dt
      }
      this.playMusic(row, musicInfo)
    },
    async getComments (isFirst = false) {
      this.loading = true
      const params = {
        id: this.playlistId,
        limit: this.pageSize,
        offset: (this.page - 1) * this.pageSize
      }
      const { data: res } = await commentsAPI(params, 'playlist')
      if (isFirst) {
        if (Object.prototype.hasOwnProperty.call(res, 'hotComments')) {
          this.hotComments = res.hotComments
        }
      }
      this.total = this.playlistInfo.commentCount - this.hotComments.length
      this.comments = res.comments
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    playAll () {
      const allSongs = this.allData
      this.$store.commit('clearMusicQueue')
      for (const item of allSongs) {
        const obj = {
          duration: item.dt,
          id: item.id,
          imgUrl: item.al.picUrl,
          artistInfo: item.ar,
          songName: item.name
        }
        this.$store.commit('changeMusicQueue', obj)
      }
      // 若第一首歌无版权无法播放，会出现bug，自动播放也是
      this.$store.commit('changeNowIndex', 0)
      this.play(allSongs[0])
    }
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.indexshow = false
      this.imgwidth = 70
      this.songwidth = 90
      this.singerwidth = 100
      this.timewidth = 50
      this.pagi = 'prev,jumper,next'
    }
  }
}
</script>

<style scoped>
.add-ball {
  z-index: 19;
  position: fixed;
  color: #ff0000;
  transition: top 0.5s ease-in, left 0.5s linear;
}

ul {
  list-style: none;
}

.playlist {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.playlist-top-card {
  display: flex;
  align-items: center;
}

.playlist-img-wrap {
  width: 200px;
  height: 200px;
}

.playlist-img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.playlist-info {
  max-width: 650px;
  padding: 0 20px;
  flex: 1;
}

.playlist-info .tag1 {
  color: #ff0000;
  height: 30px;
  line-height: 30px;
  border: 2px solid #ff0000;
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 0px 7px;
  cursor: default;
}

.playlist-name {
  font-size: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.playlist-user-info {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.playlist-user-info span {
  margin-left: 20px;
}

.playlist-user-info img {
  margin: 10px 0;
}

.playlist-user-avatar img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.playlist-user-name {
  margin: 0 10px;
}

.playAllBtn {
  height: 25px;
  line-height: 25px;
  display: inline-block;
  border-radius: 20px;
  background: #ff0000;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer;
}

.playlist-tags {
  margin: 10px 0;
}

.playlist-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.playlist-desc span:nth-of-type(2) {
  font-size: 14px;
  line-height: 20px;
}

.playlist-tags .tags {
  font-size: 14px;
}

.playlist-tags .tags:not(:last-of-type)::after {
  content: '/';
  margin: 0 4px;
}

.playlist-tabs-wrap {
  margin-top: 20px;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: none;
}

.el-table::before {
  opacity: 0;
}

.songs-table {
  width: 100%;
}

/* 设置滚动条样式 */
/* 滚动条整体样式 */
.songs-table >>> ::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
/* 滚动条里面的小方块 */
.songs-table >>> ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #f2f2f2;
}
/* 滚动条里面的轨道 选择不显示 */
.songs-table >>> ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #fff;
}

.img-wrap {
  width: 60px;
  height: 60px;
  position: relative;
}

.img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.img-wrap p::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  font-size: 14px;
  cursor: pointer;
}

.comment-title {
  margin-bottom: 20px;
}

.comment-wrap ul li {
  width: 900px;
  display: flex;
  margin: 10px 0 30px 0;
}

.comment-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.comment-info {
  flex: 1;
  font-size: 14px;
  margin-left: 10px;
}

.comment {
  margin-bottom: 10px;
}

.comment-user {
  color: #517eaf;
  margin-right: 10px;
  cursor: pointer;
}

.comment-content {
  display: inline;
}

.comment-time {
  color: grey;
  margin-right: 20px;
  margin-top: 5px;
}

.comment-bottom {
  display: flex;
  align-items: center;
  font-size: 14px !important;
}

.re-comment {
  background-color: #f3f1f3;
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
}
.plus {
  position: absolute;
  padding: 10px;
  border-radius: 50%;
  margin-left: 10px;
  left: -80px;
  top: 0;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  display: none;
}
.songs-table .el-table__row:hover .plus {
  display: block;
}

@media screen and (max-width: 479px) {
  .playlist {
    padding: 0;
  }
  .playlist-img-wrap {
    width: 26vw;
    height: 30vw;
  }
  .playlist-info {
    padding: 0;
    margin-left: 1vw;
  }
  .playlist-info .tag1 {
    height: 6vw;
    line-height: 6vw;
    width: 12vw;
    border: 1px solid #ff0000;
    font-size: 15px;
    margin-right: 0.5vw;
    padding: 0;
    text-align: center;
  }
  .playlist-name {
    line-height: 18px;
  }
  .playlist-name {
    font-size: 14px;
  }
  .playlist-name :last-child {
    overflow: hidden;
    height: 18px;
    text-overflow: ellipsis;
  }
  .playlist-user-avatar img {
    height: 8vw;
    width: 8vw;
    margin: 0 3vw 0 0;
  }
  .playlist-user-info {
    flex-wrap: wrap;
    margin: 2vw 0;
  }
  .playlist-user-name {
    margin: 0 1vw;
    font-size: 15px;
    width: 40vw;
  }
  .playlist-user-info span {
    margin-left: 0;
  }
  .playlist-create-time {
    font-size: 13px;
    margin-bottom: 1vw;
    display: none;
  }
  .playAllBtn {
    height: 5vw;
    line-height: 5vw;
    font-size: 12px;
    margin: 0 0 2vw 0;
    padding: 1vw 1.5vw;
  }
  .playlist-tags,
  .playlist-desc {
    margin: 1vw 0;
    font-size: 14px !important;
  }
  .el-table .el-table__cell {
    padding: 0;
  }
  .plus {
    display: none !important;
  }
  .comment-wrap ul li {
    width: 82vw;
    display: flex;
    margin: 10px 0 30px 0;
  }
  .el-pagination >>> .el-pagination__jump {
    margin: 0 2vw !important;
  }
  .el-pagination {
    margin: 2vw 0;
  }
  .playlist-tabs-wrap {
    margin-top: 1vw;
  }
  .comment-content {
    line-height: 17px;
  }
}
</style>
